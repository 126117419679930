import React, { Component } from 'react';
import QuizContainer from '../State';
import { Subscribe } from 'unstated';

class Hint extends Component {
  render () {
    return (
      <Subscribe to={[QuizContainer]} >
        {store => (
          <div className="wrapper">
          <div className="button message">
            <div
              dangerouslySetInnerHTML={{__html: store.state.hint}}
            />

          </div>
          { store.state.email &&
            <div className="bottom">Player: <b>{store.state.email}</b> <button className="button all-long" onClick={() => store.logout()}>logout</button></div>
          }
        </div>
          )}
      </Subscribe>
    )
  }
}

export default Hint;