import React, { Component } from 'react';
import QuizContainer from '../State';
import { Subscribe } from 'unstated';

class Wrong extends Component {

  render () {
    return (
      <Subscribe to={[QuizContainer]} >
        {store => (
            <div className="message">
              Wrong answer!<br/> You have {store.state.attempts} more chances.
              <button
                style={{
                  margin: '10vw auto',
                  textAlign: 'center'
                }}
                disabled={store.state.loading}
                onClick={() => store.fetchQuestion()}
                className="button bg-light-blue">
                RETRY
              </button>
            </div>
        )}
      </Subscribe>
    )
  }
}

export default Wrong;