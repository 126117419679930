import React, { Component } from 'react';
import QuizContainer from '../State';
import {Subscribe} from 'unstated'
import '../css/home.css'

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {email: ''};

    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(event) {
    this.setState({email: event.target.value});
  }

  render () {
    return (
    <Subscribe to={[QuizContainer]} >
      {store => (
          <div className="home">
            <div className="register">
              <p>
                Get ready for the hunt. <br/> Let's play!
              </p>
              <label htmlFor="email"> Sign-In </label>
              <div className="error">{store.state.error}</div>
              <input id="email" type="email" value={this.state.value} onChange={this.handleChange} placeholder="email" />
              <button className="button" disabled={store.state.loading} onClick={() => store.registerPlayer(this.state.email)} > START </button>
              <div className="privacy bottom">
                &copy; 2023 <br/>
                By pressing start you agree to our terms about
                the data use policy.
              </div>
            </div>
          </div>
        )}
    </Subscribe>
    )
  }
}

export default Home;
