import {Container} from 'unstated';
import axios from 'axios';
import queryString from 'query-string';

class QuizContainer extends Container {
  state = {
    question: null,
    currentView: 'Home',
    error: '',
    eventID: '',
    checkpointID: '',
    email: null,
    hint: '',
    loading: false,
    attempts: 4
  };

  constructor (props) {
    super(props);

    let event = queryString.parse(window.location.search).event;
    let checkpoint = queryString.parse(window.location.search).checkpoint;

    try {
      if (typeof event === 'undefined') {
        this.setState({hint: 'Scan our qr-code and play!', currentView: 'Hint', loading: false})
        throw new Error('Manca l\'evento nella query string')
      }
      if (typeof checkpoint === 'undefined') {
        this.setState({hint: 'Scan our qr-code and play!', currentView: 'Hint', loading: false})
        throw new Error('Manca il checkpoint nella query string')
      }

      let email = localStorage.getItem('email-' + event);

      if (email === null) {
        this.setState({
          eventID: event,
          checkpointID: checkpoint
        })
      } else {
        this.setState({
          eventID: event,
          checkpointID: checkpoint,
          email: email
        }).then(data => {this.fetchQuestion()})
      }

    } catch (e) {
      console.log(e.message)
    }

  }

  setView = (view) => {
    this.setState({currentView: view})
  }

  startQuiz = async () => {
    await this.fetchQuestion()
    this.setView('Quiz')
  }

  fetchQuestion = async () => {
    try {
      this.setState({loading: true})
      let response = await axios.get(`https://api.quiz.fabulab.com/api/question?event=${this.state.eventID}&email=${this.state.email}&checkpoint=${this.state.checkpointID}`);
      if (typeof response.data.hint === 'undefined') {
        let question = response.data;
        this.setState({question: question, currentView: 'Quiz', loading: false});
      } else {
        this.setState({hint: `You have already solved this checkpoint, the hint for the next checkpoint is: <br><br> ${response.data.hint}`, currentView: 'Hint', loading: false});
      }
    } catch (error) {
      if (error.response.data.name === 'you-lose') {
        this.setState({hint: 'GAME OVER', currentView: 'Hint', loading: false})
      } else if (error.response.data.name === 'you-win') {
        this.setState({hint: 'Well done! Come back to the stand and discover your ranking position.', currentView: 'Hint', loading: false})
      } else {
        this.setState({
          hint: error.response.data.message,
          currentView: 'Hint',
          loading: false
        })
      }
    }
  }

  registerPlayer = async (email) => {
    this.setState({error: '', loading: true})
    let eventID = queryString.parse(window.location.search).event;
    try {
      await axios.post('https://api.quiz.fabulab.com/api/players', {
        eventID: eventID,
        email: email
      });
      window.localStorage.setItem('email-'+eventID, email);
      await this.setState({loading: false, email: email});
      await this.fetchQuestion();
    } catch (error) {
      this.setState({error: error.response.data.message, loading: false})
    }
  }

  //TODO: creare una view per il wingame e mandarcelo
  //TODO: gestire il caso dell'evento chiuso
  // {message: 'Event no more playable.', code: 400}
  checkAnswer = async (answer) => {
    this.setState({loading: true})
    let response = await axios.get(`https://api.quiz.fabulab.com/api/answer?event=${this.state.eventID}&email=${this.state.email}&checkpoint=${this.state.checkpointID}&answer=${encodeURIComponent(answer)}`);
    if (response.data.status === 'correct' || response.data.status === 'win') {
      await this.setState({hint: response.data.hint, currentView: 'Hint', loading: false});
    }  else if (response.data.status === 'lose') {
      this.setState({hint: 'GAME OVER', currentView: 'Hint', loading: false});
    } else {
      await this.setState({attempts: response.data.remainingAttempts, currentView: 'Wrong', loading: false});
    }
  }

  logout() {
    localStorage.removeItem('email-' + this.state.eventID);
    window.location = window.location.href.split("?")[0];
  }

}

export default QuizContainer;
