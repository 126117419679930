import React, { Component } from 'react';
import QuizContainer from '../State';
import { Subscribe } from 'unstated'

class Quiz extends Component {

  render() {
    return (
      <Subscribe to={[QuizContainer]} >
        {store => (
          <div className="board">
            <div className="question" dangerouslySetInnerHTML={{__html: store.state.question.question}} />
            <div style={{flexGrow: 1}}>
              {store.state.question.answers.map( (answer,index) =>
              <button
                onClick={() => store.checkAnswer(answer)}
                disabled={store.state.loading}
                key={index}
                className="button answer"
              > {answer} </button>
              )}
              </div>
            <div className="bottom">Player: <b>{store.state.email}</b> <button className="button" onClick={() => store.logout()}>logout</button></div>
          </div>
        )}
      </Subscribe>
    )
  }
}

export default Quiz;
