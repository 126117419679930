import React, { Component } from 'react';
import './App.css';
import {Subscribe, Provider} from 'unstated'
import QuizContainer from "./State";
import Hint from './views/Hint'
import Home from './views/Home'
import Quiz from './views/Quiz'
import Wrong from './views/Wrong'

class App extends Component {

  renderView (view, store) {
    switch (view) {
      case 'Hint': return <Hint store={store} />
      case 'Home': return <Home store={store} />
      case 'Quiz': return <Quiz store={store} />
      case 'Wrong': return <Wrong store={store} />
      default:
    }
  }

  render() {
    return (
      <div className="App">
        <div className="header">
          <img src='fabulab-reduced.svg' alt='Fabulab' className='logo' />
          <div className='title'>CACCIA AL TESORO</div>
        </div>
        <div className="body">
          <Provider>
            <Subscribe to={[QuizContainer]} >
              {store => (
                this.renderView(store.state.currentView, store)
                )}
            </Subscribe>
          </Provider>
        </div>
      </div>
    );
  }
}

export default App;
